<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo d-none d-md-block">
        <LogoSAL />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="d-none d-md-block font-weight-bold mb-1 mt-2 mt-md-0">
            Welcome to STAR 👋
          </b-card-title>
          <b-card-title title-tag="h2" class="d-block d-md-none font-weight-bold mb-1 mt-2 mt-md-0">
            Welcome to STAR 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Sinergi, Taat, Aman & Responsif
            <hr/>
            <b-link class="brand-logo d-block d-md-none">
              <LogoSAL style="width: 50px; height: auto; "/>
            </b-link>
            <b-badge variant="primary"> Pengguna Jasa </b-badge> ,
            <b-badge variant="primary"> BBM agents </b-badge> and
            <b-badge variant="primary"> Karyawan </b-badge>
          </b-card-text>

          <!-- Error's alert -->
          <b-alert v-if="error" variant="danger" show>
            <div class="alert-body font-small-2">
              <p v-text="error" />
            </div>
            <feather-icon
              icon="InfoIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10"
            />
          </b-alert>
          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="name@salpalaran.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <!-- :to="{name:'auth-forgot-password-v2'}" -->
                  <b-link>
                    <small @click="comingSoon()">Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
                :disabled="loading"
              >
              <b-spinner v-if="loading" small />
                 Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <!-- :to="{name:'page-auth-register-v2'}" -->
            <b-link>
              <span @click="createAccount()">&nbsp;Create an account</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">or</div>
          </div>

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
              @click="comingSoon()"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
              @click="comingSoon()"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
              @click="comingSoon()"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
              @click="comingSoon()"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import LogoSAL from '@core/layouts/components/LogoSal.vue'
import {
  BSpinner,
  BBadge,
  BAlert,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    BSpinner,
    BBadge,
    BAlert,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    LogoSAL,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      error: '',
      loading: false,
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    }
  },
  methods: {
    createAccount() {
      this.$router.push({ name: `auth-register` })
    },
    comingSoon() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Coming Soon',
          icon: 'HomeIcon',
          variant: 'success'
        }
      })
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.loading = true
          useJwt
            .login({
              email: this.userEmail,
              password: this.password
            })
            .then((response) => {
              try {
                let { visitor_user_data } = response.data

                const visitor_role = visitor_user_data.visitor_roles[0]
                const visitor_ability = visitor_user_data.visitor_abilities

                // Change user's ability to ability (singular) instead of abilities (plural)
                visitor_user_data = { ...visitor_user_data, visitor_ability }
                delete visitor_user_data.visitor_abilities

                // Change user's role to role (singular) instead of roles (plural)
                visitor_user_data = {
                  ...visitor_user_data,
                  visitor_role: visitor_role.name
                }
                delete visitor_user_data.visitor_roles

                useJwt.setToken(response.data.access_token)
                useJwt.setRefreshToken(response.data.refresh_token)
                localStorage.setItem(
                  'userDataVisitor',
                  JSON.stringify(visitor_user_data)
                )
                this.$ability.update(visitor_ability)
                console.log('[storage] an done')

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Berhasil login',
                    icon: 'EditIcon',
                    variant: 'success'
                  }
                })
                try {
                  this.$router
                    .replace(getHomeRouteForLoggedInUser(visitor_role.name))
                    .then(() => {
                      //
                    })
                    .catch((error) => {
                      Promise.reject(error)
                      this.loading = false
                    })
                    .catch(() => {
                      this.loading = false
                    })
                } catch (error) {
                  console.log('an error [routing]', error)
                }
              } catch (error) {
                console.log('[storage] an errors', error)
              }
            })
            .catch((error) => {
              try {
                this.error = error.response.data.message
              } catch (err) {
                this.error = error
              }
              this.loading = false
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
