<template>
  <b-img
    src="https://api.salpalaran.com/QRCode/logo.png"
    alt="logo"
    class="ml-2"
    :style="'width: 9em; height: 9em; object-fit: contain; border-radius: 50%; box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); border: 4px solid #000; background-color: #fff; padding: 5px;'"
  />
</template>
<script>
import {
  BImg
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
}
</script>